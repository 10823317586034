import React from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"

const ThreeWheelsUnited = () => {
    return (
        <Layout>
            <Seo title="Three Wheels United" />
            <div className="container py-3 py-lg-4">Three Wheels United</div>
        </Layout>
    )
}

export default ThreeWheelsUnited
